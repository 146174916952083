import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/common/Page'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageHead from '@/components/app/PageHead'
import Pagination from '@/components/common/Pagination'
import PostList from '@/components/modules/Post/List'
import Breadcrumb from '@/components/app/Breadcrumb.container'

const BlogListPage = props => {
  const siteMeta = props.data.site.siteMetadata
  const posts = props.data.allMarkdownRemark.edges.map(edge => {
    const postDate = new Date(edge.node.frontmatter.date)
    return {
      ...edge.node.frontmatter,
      description: edge.node.excerpt,
      date: {
        fullYear: `${postDate.getFullYear()}`,
        year: `${postDate.getFullYear()}`.substring(2, 4),
        month: `${postDate.getMonth() + 1}`,
        date: `${postDate.getDate()}`
      },
      slug: edge.node.fields.slug
    }
  })
  const title = 'ERPブログ'
  const description = 'ERPとは何かや、ERPの可能性・導入活用方法などのハウツーお役立ちコンテンツはもちろん、エクセルツールの無料ダウンロードまでさまざまな情報発信を行います。'
  return (
    <Root>
      <SEO location={props.location} title={title} description={description}/>
      <LD json={{
        '@type': 'CollectionPage',
        '@id': `${siteMeta.siteUrl}/blog/#collectionpage`,
        name: `${title} | ${siteMeta.title}`,
        description: description,
        url: `${siteMeta.siteUrl}/blog/`,
        inLanguage: 'ja',
        datePublished: '2019-11-10T10:00:00+09:00',
        dateModified: '2019-11-10T10:00:00+09:00',
        isPartOf: LDEntity.WebSite(siteMeta)
      }}/>
      <Breadcrumb path="/blog/"/>
      <Head>
        <PageHead
          title={title}
          description={description}
        />
      </Head>
      <Main>
        <PostList css={styles.PostList} posts={posts} />
        <Pagination css={styles.Pagination} {...props.pageContext} path="/blog/" />
      </Main>
    </Root>
  )
}

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const styles = {
  PostList: css`
    padding: 100px 0 64px;
    @media ${mq.and(mobile)} {
      padding: 48px 0 32px;
    }
  `,
  Pagination: css`
    padding: 64px 0 100px;
    @media ${mq.and(mobile)} {
      padding: 32px 0 48px;
    }
  `
}

export default PageBase(BlogListPage)

export const BlogListQuery = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
        company {
          name
          logo
          siteUrl
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { postType: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          html
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            description
            date
            title
            tags
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 735) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            download {
              publicURL
            }
          }
        }
      }
    }
  }
`
