import React from 'react'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import Icon from './Icon'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { fonts } from '@/utils/preset'
import { getThemeColor } from '@/utils/themes'

const ActionButton = props => {
  const { theme, name, color, classes, children, className, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="outlined"
      classes={classes}
      {...other}
    >
      <Icon name={name} />
    </MuiButton>
  )
}

const getStyles = (props, theme) => {
  const color = props.color || 'text'
  return {
    backgroundColor: 'transparent',
    color: getThemeColor(color, theme),
    borderColor: getThemeColor(color, theme)
  }
}

const styles = props => {
  const styles = getStyles(props, props.theme)
  return {
    root: {
      backgroundColor: styles.background,
      color: styles.color,
      borderColor: styles.color,
      borderRadius: '50%',
      borderWidth: 4,
      width: 40,
      minWidth: 40,
      height: 40,
      fontSize: 16,
      padding: 0,
      fontFamily: fonts.base,
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: fade(styles.color, 0.05),
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        },
        '&:disabled': {
          backgroundColor: 'transparent'
        }
      },
      '&:disabled': {
        color: fade(props.theme.gray.c, 0.8),
        borderColor: fade(props.theme.gray.c, 0.8),
        borderWidth: 2
      }
    }
  }
}

export default ThemeConsumer(WithStylesProps(styles)(ActionButton))
