import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Card from './Card'

const PostList = props => {
  const { posts, ...others } = props
  return (
    <Root {...others}>
      <List>
        <List_Container>
          {posts.map((item, index) => (
            <List_Item key={index}>
              <Card {...item} />
            </List_Item>
          ))}
        </List_Container>
      </List>
    </Root>
  )
}

const Root = styled.div`
`

const List = styled.div`
`

const List_Container = styled.ul`
  margin-top: -100px;
  @media ${mq.and(tabletS)} {
    display: flex;
    flex-wrap: wrap;
    margin-top: -24px;
    margin-left: -24px;
    margin-right: -24px;
  }
  @media ${mq.and(mobile)} {
    margin-top: -24px;
  }
`

const List_Item = styled.li`
  margin-top: 100px;
  @media ${mq.and(tabletS)} {
    width: 50%;
    padding: 0 12px;
    margin-top: 24px;
    > * {
      height: 100%;
    }
  }
  @media ${mq.and(mobile)} {
    margin-top: 24px;
  }
`

export default ThemeConsumer(PostList)
