import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import GatsbyImage from 'gatsby-image'
import { tabletS, mobile } from '@/utils/media'
import { mq, aspectRatio, aspectRatioChild } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import Tag from '@/components/common/Tag'

const PostCard = props => {
  const { slug, thumbnail, subtitle, title, tags, date, description, ...others } = props
  return (
    <Root {...others} to={slug}>
      <Head>
        <Thumbnail>
          <Image fluid={thumbnail.childImageSharp.fluid}></Image>
        </Thumbnail>
      </Head>
      <Main>
        {subtitle && (
          <Subtitle variant="h6" theme={props.theme}>{subtitle}</Subtitle>
        )}
        {tags && tags.length && (
          <Tags>
            {tags && tags.map((tag, index) => (
              <Tags_Item key={index}>
                <Tag color="primary">{tag}</Tag>
              </Tags_Item>
            ))}
          </Tags>
        )}
        <Title variant="h4" theme={props.theme}>{title}</Title>
        {date && (
          <Date
            variant="caption2"
            component="time"
            theme={props.theme}
            dateTime={`${date.fullYear}-${date.month}-${date.date}`}>
            {`${date.fullYear}.${('00' + date.month).slice(-2)}.${('00' + date.date).slice(-2)}`}
          </Date>
        )}
        <Description
          variant="body2"
          theme={props.theme}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Spacer />
        <More variant="body2" theme={props.theme}>もっと読む</More>
      </Main>
    </Root>
  )
}

export default ThemeConsumer(PostCard)

const Root = styled(Link)`
  display: flex;
  border: 1px solid ${props => props.theme.line.base};
  @media (hover: hover) {
    transition: background-color ease 0.2s;
    &:hover {
      background-color: ${props => props.theme.background.hover.base};
    }
  }
  @media ${mq.and(tabletS, mobile)} {
    flex-direction: column;
  }
`

const Head = styled.div`
  width: 490px;
  flex-shrink: 0;
  flex-grow: 0;
  @media ${mq.and(tabletS, mobile)} {
    width: 100%;
    ${aspectRatio(490, 307)};
  }
`

const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  @media ${mq.and(tabletS, mobile)} {
    ${aspectRatioChild()};
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const Main = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  padding: 40px;
  @media ${mq.and(tabletS, mobile)} {
    padding: 20px;
  }
`

const Subtitle = styled(Typography)`
  color: ${props => props.theme.gray.e};
  margin-bottom: 8px;
`

const Date = styled(Typography)`
  display: block;
  margin-top: 8px;
`

const Title = styled(Typography)`
  color: ${props => props.theme.gray.e};
  width: 100%;
`

const Description = styled(Typography)`
  margin-top: 24px;
  width: 100%;
  @media ${mq.and(tabletS, mobile)} {
    display: none;
  }

  ul,
  ul ul,
  ol ul {
    > li {
      position: relative;
      margin-top: 4px;

      &::before {
        content: '';
        position: absolute;
        top: 9.5px;
        left: -2.5px;
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: currentColor;
      }
      position: relative;
      padding-left: 12px;

      &:empty::before {
        display: none;
      }
    }
  }
`

const Spacer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-left: -8px;
  margin-bottom: 8px;
`

const Tags_Item = styled.div`
  margin-top: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
`

const More = styled(Typography)`
  margin-top: 18px;
  text-align: right;
  color: ${props => props.theme.primary.base};
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 24px;
    text-align: left;
  }
`
